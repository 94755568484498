import React from "react";
import { urlFor } from "../client";

function MainProject({ ig, text, delay, onClick }) {
  return (
    <div
      data-aos="fade-up"
      onClick={onClick}
      data-aos-delay={delay}
      className="h-[130px] w-[150px] rounded-sm  bg-white sm:h-[200px] sm:w=[200px]  lg:w-[190px] lg:h-[190px] xl:w-[240px] xl:h-[240px] cursor-pointer relative  text-white flex  rounded-sm "
    >
      <a className="absolute inset-0 z-10 bg-white text-center justify-end items-start py-5 px-4 flex flex-col  opacity-0 hover:opacity-80 bg-opacity-90 duration-300">
        <span className=" text-black sm:text-xl  text-xs	 font-DMsan font-bold">
          {text}
        </span>
        <p className=" text-black sm:text-sm  text-xs font-DMsan">
          Check details
        </p>
      </a>

      <div className="w-full h-full bg-white flex justify-center items-center">
        <img src={urlFor(ig)} className="w-full h-full object-cover" />
      </div>
    </div>
  );
}

export default MainProject;
