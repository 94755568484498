import MainHome from "./Pages/MainHome";
import ProjectPage from "./Pages/ProjectPage";
import ReviewPage from "./Pages/ReviewPage";
import ProjectDisplay from "./Pages/ProjectDisplay";
import ReviewDetails from "./Pages/ReviewDetails";

import ScrollToTop from "./Components/ScrollToTop";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="font-DMsan scrollbar-hide bg-black">
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/Projects" element={<ProjectPage />} />
            <Route path="/Review" element={<ReviewPage />} />
            <Route path="/ProjectDetails" element={<ProjectDisplay />} />
            <Route
              path="Projects/ProjectDetails"
              element={<ProjectDisplay />}
            />
            <Route path="/ReviewDetails" element={<ReviewDetails />} />
            <Route path="Review/ReviewDetails" element={<ReviewDetails />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
