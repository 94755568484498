import React from "react";
import { urlFor } from "../client";

function ReviewHolder({ ig, onClick }) {
  return (
    <div
      onClick={onClick}
      className="h-[130px] w-[150px] sm:h-[200px] sm:w=[200px]  lg:w-[190px] lg:h-[190px] xl:w-[240px] xl:h-[240px] cursor-pointer relative  text-white flex  rounded-sm "
    >
      <a className="absolute inset-0 z-10 bg-black text-center justify-end items-center py-10 flex flex-col  opacity-0 hover:opacity-80 bg-opacity-90 duration-300">
        <p className=" text-white text-xl font-DMsan">Check Review</p>
      </a>

      <div className="w-full h-full bg-black flex justify-center items-center">
        <img src={urlFor(ig)} className="w-full h-full object-cover" />
      </div>
    </div>
  );
}

export default ReviewHolder;
