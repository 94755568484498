import React from "react";
import Nabbar2 from "../Components/Nabbar2";

import ReactPlayer from "react-player";
import { useParams, useNavigate, useLocation } from "react-router-dom";

function ProjectDisplay() {
  const location = useLocation();

  console.warn(location.state.data.video);

  const [video, setVideo] = React.useState(location.state.data.video);
  const [name, setName] = React.useState(location.state.data.name);

  React.useEffect(() => {
    setVideo(location.state.data.video);
  }, []);

  return (
    <>
      <Nabbar2 />

      <div className="w-full h-[900px] sm:px-24 px-5  sm:pb-24 pb-32  bg-black text-white   flex flex-col items-center justify-center transition duration-300">
        <div
          data-aos="fade-up"
          data-aos-delay="20"
          className="w-full h-[90px]   justify-center sm:justify-center lg:justify-start items-center flex pl-5"
        >
          <span className="lg:text-2xl sm:text-xl text-lg text-white font-DMsan font-bold ">
            {name}.
          </span>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="20"
          className="w-full h-[80%]   sm:h-[80%]  flex justify-center items-center  "
        >
          {/* <YouTube videoId={id} opts={opts} style={{ width: "50px" }} /> */}
          <ReactPlayer
            url={video}
            loop={false}
            width={"100%"}
            height={"100%"}
            controls={true}
            autoplay={true}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectDisplay;
