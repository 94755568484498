import React from "react";
import Logo from "../Assets/logo.png";

import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenuAlt4 } from "react-icons/hi";

function Header() {
  const [nav, setNav] = React.useState(false);
  const [logo, setLogo] = React.useState(false);
  const handleNav = () => {
    setNav(!nav);
    setLogo(!logo);
  };

  return (
    <div className="md:w-full md:px-24 px-5 md:h-[70px] h-[70px] bg-black flex md:justify-center justify-between z-10 ">
      <div className="w-[10%] h-full  md:justify-center md:items-center  md:flex hidden">
        <img src={Logo} className="w-[50%] h-[50%] object-contain" />
      </div>
      <div className="w-[90%] h-full text-white md:flex hidden">
        <ul className="w-full h-full flex items-center justify-evenly">
          <li>
            <a
              href="#"
              class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300"
            >
              <span className="  sm:text-lg text-sm">Home</span>
              <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
            </a>
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 580, left: 0, behavior: "smooth" });
            }}
          >
            <a class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300">
              <span className="  sm:text-lg text-sm">Services</span>
              <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
            </a>
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 980, left: 0, behavior: "smooth" });
            }}
          >
            <a class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300">
              <span className="  sm:text-lg text-sm">Projects</span>
              <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
            </a>
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 1480, left: 0, behavior: "smooth" });
            }}
          >
            <a class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300">
              <span className="  sm:text-lg text-sm">Reviews</span>
              <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
            </a>
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 2180, left: 0, behavior: "smooth" });
            }}
          >
            <a class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300">
              <span className="  sm:text-lg text-sm">Contacts</span>
              <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
            </a>
          </li>
        </ul>
      </div>

      <div className="md:hidden flex w-[20%] h-full  justify-center items-center">
        <img src={Logo} className="w-[40%] h-[40%] object-contain" />
      </div>
      <div
        onClick={handleNav}
        className="md:hidden flex w-[20%]  h-full justify-center items-center z-10"
      >
        {nav ? (
          <AiOutlineClose className="text-black" size={30} />
        ) : (
          <HiOutlineMenuAlt4 className="text-white" size={30} />
        )}
      </div>

      {/* Mobile menu dropdown */}
      <div
        onClick={handleNav}
        className={
          nav
            ? "absolute text-black left-0 top-0 w-full h-full bg-gray-100/90 px-6 py-7 flex flex-col transition-all duration-75"
            : "absolute left-[-100%] transition-all duration-75"
        }
      >
        <ul>
          <div className="w-[10%] h-full  justify-center items-center  md:flex hidden">
            <img src={Logo} className="w-[50%] h-[50%] object-contain" />
          </div>

          <li
            onClick={() => {
              window.scrollTo({ top: 2180, left: 0, behavior: "smooth" });
            }}
            className="w-full h-[60px] flex items-center text-xl"
          >
            Home
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
            }}
            className="w-full h-[60px] flex items-center text-xl"
          >
            Services
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 580, left: 0, behavior: "smooth" });
            }}
            className="w-full h-[60px] flex items-center text-xl"
          >
            Projects
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 980, left: 0, behavior: "smooth" });
            }}
            className="w-full h-[60px] flex items-center text-xl"
          >
            Reviews
          </li>
          <li
            onClick={() => {
              window.scrollTo({ top: 1480, left: 0, behavior: "smooth" });
            }}
            className="w-full h-[60px] flex items-center text-xl"
          >
            Contacts
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
