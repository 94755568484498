import React from "react";

import ContactBox from "../Components/ContactBox";

// logo
import img from "../Assets/w1.png";
import img2 from "../Assets/w2.png";
import img3 from "../Assets/w3.png";
import img4 from "../Assets/w4.png";

function ContactSection() {
  return (
    <div className="w-full sm:px-24 px-5  lg:h-[320px] h-[540px] pb-5 bg-black text-white  flex flex-col items-center transition duration-300 ">
      <div
        data-aos="fade-up"
        data-aos-delay="20"
        className="w-full h-[90px]  justify-center sm:justify-center lg:justify-start   items-center flex pl-5"
      >
        <span className="lg:text-2xl  sm:text-xl text-lg text-white font-DMsan font-bold ">
          CONTACT.
        </span>
      </div>

      <div className="w-full h-[30%] sm:h-[40%]   grid lg:grid-cols-4 place-items-center grid-cols-2 grid-flow-row gap-2  ">
        {/* 21 words are the limit */}
        <ContactBox
          onClick={() => {
            window.open("https://wa.me/+923040607747");
          }}
          delay={"100"}
          ig={img4}
        />
        <ContactBox
          onClick={() => {
            window.open("https://www.linkedin.com/in/umer-aftab-7783961b4/");
          }}
          delay={"200"}
          ig={img2}
        />
        <ContactBox
          onClick={() => {
            window.open("https://github.com/Umeraftab7747");
          }}
          delay={"300"}
          ig={img3}
        />
        <ContactBox
          onClick={() => {
            window.open("https://www.behance.net/umeraftab2");
          }}
          delay={"400"}
          ig={img}
        />
      </div>
    </div>
  );
}

export default ContactSection;
