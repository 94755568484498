import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "8oz5x48x",
  dataset: "production",
  apiVersion: "2022-02-01", // use current UTC date - see "specifying API version"!
  token:
    "skZKUhHWHFevH3PQYN19n1UxGy0gfzSz0A7rgvUZ2nFX8ngzhpuFkCLv0kcfFoiRtUpbs10R8QgV9k6f5jWc0Cp4SvZInyEwChqQWe8VzNZ4DBS77olh7jwQMwoXCMaNY2nXyfdn5h4JG4KQYEWJlka2nkHmcZcaandGMGDUEp7p2N4PtVwN", // or leave blank for unauthenticated usage
  useCdn: true, // `false` if you want to ensure fresh data
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
