import React, { useEffect } from "react";
import Nabbar2 from "../Components/Nabbar2";
import MainProject from "../Components/MainProject";
import { Link, useNavigate } from "react-router-dom";
import { urlFor, client } from "../client";

import Lottie from "lottie-react";
import darkLotti from "../Assets/lotti/Loading.json";

function ProjectPage() {
  const navigate = useNavigate();

  const [Projects, setProjects] = React.useState("");

  useEffect(() => {
    const query = '*[_type == "projects"]';
    client.fetch(query).then((data) => {
      console.log(data);
      setProjects(data);
    });
  }, []);

  if (Projects === "") {
    return (
      <>
        <Nabbar2 />
        <div className="w-full sm:px-24 px-5 min-h-screen  pb-10  bg-black text-white sm:pt-5  flex flex-col items-center  transition duration-300">
          <div className="w-full h-[100px]  mt-36 justify-center sm:justify-center lg:justify-start   items-center flex pl-5">
            <Lottie
              animationData={darkLotti}
              loop={true}
              className={"w-full h-full object-contain"}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Nabbar2 />

      <div className="font-DMsan bg-black">
        <div className="w-full sm:px-24 px-5  min-h-screen pb-10  bg-black text-white sm:pt-5  flex flex-col items-center  transition duration-300 ">
          <div
            data-aos="fade-up"
            data-aos-delay="20"
            className="w-full h-[90px] justify-center sm:justify-center lg:justify-start   items-center flex pl-5"
          >
            <span className="lg:text-2xl sm:text-xl text-lg text-white font-DMsan font-bold ">
              PROJECTS.
            </span>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="20"
            className="w-full  h-[40%] sm:h-[60%]  grid lg:grid-cols-4 place-items-center grid-cols-2 grid-flow-row gap-2  "
          >
            {Projects &&
              Projects.map((item) => {
                console.warn();
                return (
                  <MainProject
                    onClick={() => {
                      navigate("ProjectDetails", {
                        state: {
                          data: item,
                        },
                      });
                    }}
                    ig={item.Img}
                    text={item.name}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectPage;
