import React from "react";
import Logo from "../Assets/lgo2.png";

function HeroSection() {
  return (
    <div className="w-full sm:px-24 px-5 lg:h-[550px] h-[310px]  bg-black text-white  flex flex-col items-center justify-center  transition duration-300">
      <div
        data-aos="fade-up"
        data-aos-delay="10"
        className="lg:w-[390px] lg:h-[170px] h-[70px]  w-[190px]"
      >
        <img src={Logo} className="w-full h-full object-contain" />
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="200"
        className="lg:w-[700px] lg:h-[90px] h-[190px] w-[320px]  "
      >
        <p className="font-DMsan lg:text-lg text-xs mt-5">
          I am a Creative Developer with a 3-year background in building and
          implementing functional, mobile applications. Excellent
          problem-solving skills with a keen eye for detail.Outstanding
          knowledge of coding languages React.js & React-Native.
        </p>
      </div>
    </div>
  );
}

export default HeroSection;
