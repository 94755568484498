import React from "react";
import ServiceHolder from "../Components/ServiceHolder";

// logo
import Logo1 from "../Assets/ui.png";
import Logo2 from "../Assets/mobile.png";
import Logo3 from "../Assets/web.png";

function ServicesSection() {
  return (
    <div className="w-full sm:px-24 px-5  lg:h-[470px] h-[280px] bg-white text-black sm:pt-5  flex flex-col items-center justify-center transition duration-300">
      <div
        data-aos="fade-up"
        data-aos-delay="20"
        className="w-full h-[90px] justify-center sm:justify-center lg:justify-start   items-center flex pl-5"
      >
        <span className="lg:text-2xl sm:text-xl text-lg text-black font-DMsan font-bold ">
          SERVICES.
        </span>
      </div>

      <div className="w-full h-full flex justify-evenly pb-5 ">
        <ServiceHolder
          onClick={() => {
            window.open(
              "https://www.upwork.com/freelancers/~0100addba87294162e"
            );
          }}
          delay={"100"}
          ig={Logo2}
          text={"UI / UX"}
        />
        <ServiceHolder
          onClick={() => {
            window.open(
              "https://www.upwork.com/freelancers/~0100addba87294162e"
            );
          }}
          delay={"200"}
          ig={Logo1}
          text={"Android & IOS"}
        />
        <ServiceHolder
          onClick={() => {
            window.open(
              "https://www.upwork.com/freelancers/~0100addba87294162e"
            );
          }}
          delay={"300"}
          ig={Logo3}
          text={"Web & Apps"}
        />
      </div>
    </div>
  );
}

export default ServicesSection;
