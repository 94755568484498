import React from "react";

function ContactBox({ ig, text, delay, onClick }) {
  return (
    <a
      onClick={onClick}
      data-aos="fade-up"
      data-aos-delay={delay}
      href="#"
      class="group cursor-pointer items-center justify-center pt-16 transition duration-300"
    >
      <div className="w-[100px] h-[70px] bg-black text-white pb-2  flex items-center flex-col rounded-sm ">
        <img src={ig} className="w-full h-full object-contain" />
      </div>
      <span class="block max-w-0 group-hover:max-w-[100%] transition-all duration-500 h-0.5 bg-white"></span>
    </a>
  );
}

export default ContactBox;
