import React from "react";

function ServiceHolder({ ig, text, delay, onClick }) {
  return (
    <div
      onClick={onClick}
      data-aos="fade-up"
      data-aos-delay={delay}
      className="lg:w-[250px] lg:h-[250px] md:w-[180px] md:h-[200px] w-[100px] h-[140px] text-white bg-black flex items-center flex-col rounded-sm "
    >
      <div className="w-full lg:h-[40%] h-[70%] flex justify-center items-center">
        <img src={ig} className="w-[70%] h-[70%]  object-contain" />
      </div>
      <span className="pt-2 lg:text-lg text-xs ">{text}</span>
      <a
        href="#"
        class="group cursor-pointer lg:block hidden  pt-16 transition duration-300"
      >
        <span className=" text-lg text-white">Get it now !</span>
        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
      </a>
    </div>
  );
}

export default ServiceHolder;
