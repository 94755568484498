import React from "react";
import Header from "../Components/Header";
import ContactSection from "../Sections/ContactSection";
import HeroSection from "../Sections/HeroSection";
import ProjectSection from "../Sections/ProjectSection";
import ReviewSection from "../Sections/ReviewSection";
import ServicesSection from "../Sections/ServicesSection";

function MainHome() {
  return (
    <div className="font-DMsan scrollbar-hide">
      <Header />
      <HeroSection />
      <ServicesSection />
      <ProjectSection />
      <ReviewSection />
      <ContactSection />
    </div>
  );
}

export default MainHome;
