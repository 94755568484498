import React from "react";

import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

function Nabbar2() {
  return (
    <div className="md:w-full  md:px-24 px-5 md:h-[70px] h-[70px] bg-black flex md:justify-center justify-between z-10 ">
      <div className="w-[10%] h-full   justify-center items-center flex cursor-pointer ">
        <Link to={"/"} href="/Projects">
          <IoArrowBackOutline className="text-white" size={35} />
        </Link>
      </div>

      <div className="w-[90%] h-full text-white md:flex hidden"></div>
    </div>
  );
}

export default Nabbar2;
