import React, { useEffect } from "react";

import MainProject from "../Components/MainProject";
import { Link, useNavigate } from "react-router-dom";

import { urlFor, client } from "../client";

function ProjectSection() {
  const navigate = useNavigate();

  const [Projects, setProjects] = React.useState("");

  useEffect(() => {
    const query = '*[_type == "mainprojects"]';
    client.fetch(query).then((data) => {
      console.log(data);
      setProjects(data);
    });
  }, []);

  return (
    <div className="w-full sm:px-24 px-5  lg:h-[470px]  bg-black text-white sm:pt-5  flex flex-col items-center justify-center transition duration-300 ">
      <div
        data-aos="fade-up"
        data-aos-delay="20"
        className="w-full h-[90px] justify-center sm:justify-center lg:justify-start   items-center flex pl-5"
      >
        <span className="lg:text-2xl sm:text-xl text-lg text-white font-DMsan font-bold ">
          PROJECTS.
        </span>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="20"
        className="w-full h-[40%] sm:h-[60%]  grid lg:grid-cols-4 place-items-center grid-cols-2 grid-flow-row gap-2  "
      >
        {/* 21 words are the limit */}

        {Projects &&
          Projects.map((item) => {
            console.warn();
            return (
              <MainProject
                onClick={() => {
                  navigate("ProjectDetails", {
                    state: {
                      data: item,
                    },
                  });
                }}
                ig={item.Img}
                text={item.name}
              />
            );
          })}
      </div>

      <Link
        to={"/Projects"}
        data-aos="fade-up"
        data-aos-delay="20"
        href="/Projects"
        class="group cursor-pointer lg:pt-5 pt-14 pb-5 transition duration-300"
      >
        <span className="  sm:text-lg text-sm">Check More</span>
        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
      </Link>
    </div>
  );
}

export default ProjectSection;
